/**
 * @file wordpress.interceptor.ts
 */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/app/app.constants';
import { TokenService } from 'src/app/core/services/token/token.service';
import { APIConstants } from 'src/app/services/services.constants';

@Injectable()
export class WordpressInterceptor implements HttpInterceptor {

  constructor(private readonly tokenService: TokenService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith(`${environment.hostURL}/CMSDocker/index.php`)) {
      request = request.clone({
        setHeaders: {
          'api-token': `${environment.wpApiToken}`,
        }
      });
    }

    // Pass provider type specific to logged in user to customer support API
    const providerType = this.tokenService.getProviderType();
    if (request.url.startsWith(`${environment.hostURL}/CMSDocker/index.php`)) {
      request = request.clone({
        setHeaders: {
          'ProviderType': AppConstants.GUEST_PROVIDER_TYPE
        }
      });
      const customerSupportAPI = `${environment.hostURL}${APIConstants.WORDPRESS_URLS.CUSTOMER_SUPPORT}`;
      const pdfDownloadAPI = `${environment.hostURL}${APIConstants.WORDPRESS_URLS.PDF_DOWNLOAD}`

      if (request.url.startsWith(customerSupportAPI) || request.url.startsWith(pdfDownloadAPI)) {
        request = request.clone({
          setHeaders: {
            'ProviderType': providerType ? providerType : AppConstants.GUEST_PROVIDER_TYPE
          }
        });
      }
    }
    return next.handle(request);
  }
}

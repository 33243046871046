/**
 * @file app.constants.ts
 * @description Constants for app module
 */
export class AppConstants {
  public static readonly ERROR = {
    MESSAGES: {
      GENERIC: 'We ran into an unexpected error. Please try again.',
      FATAL: 'We ran into an unexpected error. Please refresh the application.'
    }
  };

  public static readonly LANGUAGE = {
    DEFAULT: 'en',
    ENGLISH: 'en',
    SPANISH: 'es'
  };

  public static readonly TEXT_SIZE = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large'
  };

  public static readonly LOADER = {
    DEFAULT_MESSAGE: 'Loading'
  };

  public static readonly DOCKER_CONFIG_KEYS = {
    DEMO_CLIENT: 'DEMO_CLIENT',
    HIDE_OUT_OF_NETWORK_PHARMACIES: 'HIDE_OUT_OF_NETWORK_PHARMACIES',
    DISABLE_TERMED_USER_AFTER: 'DISABLE_TERMED_USER_AFTER',
    SHOW_MAINTENANCE_DOWNTIME_MESSAGE: 'SHOW_MAINTENANCE_DOWNTIME_MESSAGE',
    BENECARD_CLIENTS_TO_ENABLE_PMART_FORM: 'BENECARD_CLIENTS_TO_ENABLE_PMART_FORM',
    DISABLE_DIGITAL_ID_CARDS:'DISABLE_DIGITAL_ID_CARDS',
    ENABLE_AUTO_REFILL_CHARGE_SHIP:'ENABLE_AUTO_REFILL_CHARGE_SHIP'
  };

  public static GUEST_PROVIDER_TYPE = 'GUEST';
  public static PROVIDER_TYPE_ORION = 'ORION';
  public static PROVIDER_TYPE_BENECARD = 'BENECARD';
  public static PASSWORD_RESET_USER_FLOW_NAME = 'B2C_1_PasswordReset';

  public static MAIL_ORDER_NPIS = ['1003053174', '1114477122', '1821120981'];
  public static CLIENT_NAME = 'CLIENT_NAME';
}

export enum USER_ELIGIBILITY_STATUS {
  FUTURE_EFFECTIVE = 'FUTURE_EFFECTIVE',
  ELIGIBLE = 'ELIGIBLE',
  TERMED = 'TERMED'
}

export enum RELATIONSHIP_CODE {
  CARDHOLDER = '1',
  SPOUSE = '2',
  CHILD = '3',
  OTHER = '4',
  STUDENT = '5',
  DISABLED = '6',
  ADULT_DEPENDENT = '7',
  SIG_OTHER = '8',
  EX_SPOUSE = '9' // master eligibility converts to '4'
}

export enum MEMBER_PORTAL_USER_TYPES {
  NORMAl = 0,
  SSO,
  GUEST
}

/**
 * @file pre-complete-profile.component.ts
 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompleteProfileService } from './complete-profile.service';
import { TokenService } from '../core/services/token/token.service';
import { AppInsightsService } from '../services/app-insights/app-insights.service';
import { AppCenterAnalytics } from '../services/app-insights/app-insights';

@Component({
  selector: 'app-pre-complete-profile',
  templateUrl: './pre-complete-profile.component.html',
  styleUrls: ['./pre-complete-profile.component.scss']
})
export class PreCompleteProfileComponent implements OnInit {

  constructor(
    private readonly active: ActivatedRoute,
    private readonly router: Router,
    private readonly tokenService: TokenService,
    private readonly completeProfileService: CompleteProfileService,
    private readonly appInsightsService: AppInsightsService,
  ) {
    active.fragment.subscribe((fragment) => {
      if (fragment) {
        const urlSearchParams = new URLSearchParams(fragment);
        this.completeProfileService.ssoToken = urlSearchParams.get('sso_token') || '';
        this.completeProfileService.user = {
          email: urlSearchParams.get('email') || '',
          dateOfBirth: urlSearchParams.get('dateOfBirth') || '',
          firstName: urlSearchParams.get('firstName') || '',
          lastName: urlSearchParams.get('lastName') || '',
          contactNumber: urlSearchParams.get('contactNumber') || '',
          cardId: urlSearchParams.get('cardId') || '',
          clientId: urlSearchParams.get('clientId') || '',
          personCode: urlSearchParams.get('personCode') || '',
          userName: urlSearchParams.get('username') || '',
        };

        if (!this.completeProfileService.ssoToken) {
          this.router.navigate(['/main']);
        } else {
          this.tokenService.setSSOToken(this.completeProfileService.ssoToken);
          this.tokenService.setProviderType();
          this.trackPageEvent();
          this.router.navigate(['complete-profile']);
        }
      }
    });
  }

  private trackPageEvent() {
    const userDetails = {
      clientID: this.completeProfileService.user.clientId,
      ProviderType: this.tokenService.getProviderType() ? this.tokenService.getProviderType() : 'unknown',
      Date: this.getDate(),
      member: this.completeProfileService.user,
    };

    this.appInsightsService.trackPageViewWithoutClientId(`${AppCenterAnalytics.PRE_COMPLETE_PROFILE_PAGE_VIEWED}`, userDetails);
  }

  getDate(): string {
    var dateToday = new Date();
    return `${dateToday.getFullYear()}/${dateToday.getMonth() + 1}/${dateToday.getDate()}`;
  }

  ngOnInit(): void {
  }

}
